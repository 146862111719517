.slick-slide
    position: relative
.slick 
    img
        width: 100%
        height: 100%
.banner-slick,.banner-slick2,.foodlist
    height: 100%
    .slick-track,.slick-list,.slick-slide
        height: 100%
    .slick-prev, .slick-next
        border: 0
        width: 40px
        height: 80px
        position: absolute
        top: 50%
        margin-top: -50px
        color: transparent
        line-height: 0 
        font-size: 0
    .slick-prev
        +bg(slick-ar1,center)
        left: 0
        +bgo(0.5)
    .slick-next
        +bg(slick-ar2,center)
        right: 0
        +bgo(0.5)
    .slick-disabled
        opacity: 0.5
    .slick-dots
        position: absolute
        bottom: 20px
        width: 100%
        text-align: center
        li
            display: inline-block
            margin: 0 5px
        button
            border: 0
            font-size: 0
            width: 60px
            height: 3px
            display: block
            padding: 0
            background-color: #FFF
        .slick-active
            button
                background-color: $main
    a 
        display: block
        width: 100%
        height: 100%
.foodlist
    .slick-list
        padding-top: 5px
        padding-left: 5px
    .slick-prev
        left: -45px 
    .slick-next
        right: -25px  
.honorslick
    .slick-prev, .slick-next
        border: 0
        width: 43px
        height: 21px
        position: absolute
        left: 308px
        color: transparent
        line-height: 0 
        font-size: 0
    .slick-prev
        +bg(vslick-ar1,center)
        top: 65px
    .slick-next
        +bg(vslick-ar2,center)
        bottom: 60px
    .slick-disabled
        opacity: 0.2
                   
.bigphoto
    .slick-prev, .slick-next
        border: 0
        width: 40px
        height: 130px
        position: absolute
        top: 50%
        margin-top: -65px
        line-height: 0 
        font-size: 0
        
    .slick-prev
        +bg(slick-prev,center) 
        background-color: #eee
        left: 100px
    .slick-next
        +bg(slick-next,center)
        background-color: #eee
        right: 100px
.photonav
    .slick-prev, .slick-next
        border: 0
        width: 40px
        height: 130px
        position: absolute
        top: 50%
        margin-top: -65px
        line-height: 0 
        font-size: 0
        
    .slick-prev
        +bg(slick-prev,center) 
        background-color: #eee
        left: 0
    .slick-next
        +bg(slick-next,center)
        background-color: #eee
        right: 0      
// ------------------------------
// ︿(￣︶￣)︿ 平板和手机
// ------------------------------
@media screen and (max-width: 1200px)
    .banner-slick,.banner-slick2
        .slick-prev,.slick-next
            display: none !important  
    .foodlist
        .slick-list
            padding-top: 0
            padding-left: 0
        .slick-prev
            left: 0 
        .slick-next
            right: 0
// ------------------------------
// ︿(￣︶￣)︿ 手机
// ------------------------------

@media screen and (max-width: 768px)
    .banner-slick
        display: none!important
    .banner-slick2
        display: block!important 
    .banner-slick,.banner-slick2
        .slick-dots
            bottom: 5px
            button
                width: 20px
    .bigphoto 
        .slick-prev, .slick-next
            display: none!important 
    .honorslick   
        .slick-prev,.slick-next
            left: 57px
        .slick-prev
            top: 20px
        .slick-next
            bottom: 0
// ------------------------------
// ︿(￣︶￣)︿ 平板
// ------------------------------

@media screen and (max-width: 1200px) and (min-width: 768px) 
    .bigphoto 
        .slick-next
            right: 20px
        .slick-prev
            left: 20px
    .foodlist
        .slick-prev
            left: 20px
        .slick-next
            right: 20px

