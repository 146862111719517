//==============================================
// @common
//==============================================

*, *:before, *:after
    -webkit-box-sizing: border-box
    -ms-box-sizing: border-box
    box-sizing: border-box
    font-family: $yh
html
    text-size-adjust: 100%
    font-size: 62.5%
    //font-size: 0.625em
body
    font-size: 14px 
    // font-size: 1.4rem 
    line-height: $baselh
    color: $basecolor
a
    color: $basecolor
    &:hover
        color: $main

.fl
    float: left
.fr
    float: right
.clear
    &:after
        display: table
        clear: both
        content: ""
table 
    width: 100%
html, body
    min-height: 100%
    height: 100%
#wrapper, #container
    min-height: 100%
    position: relative
    height: auto
    padding-top: 170px
    padding-bottom: 359px
    background-color: #fff
.w
    width: $w
    margin: 0 auto
// ------------------------------
// ︿(￣︶￣)︿ 手机
// ------------------------------
@media screen and (max-width: 768px)
    #wrapper
        padding-bottom: 221px 
        padding-top: 50px 
// ------------------------------
// ︿(￣︶￣)︿ 平板
// ------------------------------

@media screen and (max-width: 1200px) and (min-width: 768px)   
    #wrapper
        padding-bottom: 319px