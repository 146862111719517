@import _/export
.footer
    position: absolute
    bottom: 0
    width: 100%
    left: 0
.footcontact
    padding-top: 70px
    padding-bottom: 45px
    +bgj(footbg,center)
    background-size: cover
    .contactway
        +bg(contact,left)
        padding-left: 265px
        min-height: 130px
        p 
            font-size: 12px
            line-height: 24px
            color: #333
            &.hotline
                color: $main
                font-size: 18px
                font-weight: bold
                margin-bottom: 5px
    .ewcode
        width: 109px
        height: 152px
        +bg(ewcode-bg,center)
        padding-top: 30px
        padding-left: 7px
        margin-left: 80px
        img 
            border: 1px solid $line
            +br(5px)
            width: 85px
            height: 85px
.footlink
    dl 
        width: 160px
        margin-left: 80px
        &:first-child
            margin-left: 0
    dt 
        border-bottom: 1px solid #555
        margin-bottom: 5px
        span 
            border-bottom: 2px solid #ff6600
            display: inline-block
            margin-bottom: -1px
            line-height: 35px
            font-size: 18px
            font-weight: normal
            color: $main
    dd 
        a 
            display: block
            line-height: 30px
.copyright
    height: 52px
    background-color: $main
    p 
        font-size: 12px
        color: #fff
        text-align: center
        line-height: 52px
// ------------------------------
// ︿(￣︶￣)︿ 平板和手机
// ------------------------------
@media screen and (max-width: 1200px)
    .footlink
        display: none
// ------------------------------
// ︿(￣︶￣)︿ 手机
// ------------------------------

@media screen and (max-width: 768px)
    .footcontact 
        padding-top: 10px
        padding-bottom: 10px
        .ewcode
            display: none
        .contactway
            padding-left: 0
            background: none

