@import _/export
.header
    position: fixed
    top: 0
    width: 100%
    left: 0
    z-index: 100
    height: 170px
    background-color: $main
    .container
        position: relative
    .logo
        position: absolute
        top: 0
        left: 0
        z-index: 100
       
.headtop
    background-color: #e4e4e4
    height: 120px
    .fr 
        font-size: 0
    .ewcode 
        display: inline-block
        vertical-align: middle
        margin-top: 20px
        margin-left: 14px
        width: 80px
        height: 80px
        border: 1px solid $main
        +br(5px)
        padding: 5px
        img 
            width: 100%
            height: 100%
    .hotline
        margin-top: 30px
        display: inline-block
        vertical-align: middle
        +bg(hotline,top center)
        min-width: 204px
        padding-top: 28px
        font-size: 28px
        font-weight: bold
        color: $main

.iconnav
    display: none
    +bg(nav,center)
    background-size: 30px auto
    width: 50px
    height: 50px
    position: absolute
    top: 0
    right: 0
    z-index: 10
.headnav
    
    font-size: 0
    padding: 7px 0
    li 
        display: inline-block
        
        &:hover,&.on 
            a 
                color: $main
                background-color: #dbb966
                border-color: #c7910f
                +fix(transition,color 0.2s)
                &:before,&:after
                    display: block
    a 
        display: block
        font-weight: bold
        color: #fff
        font-size: 14px
        line-height: 35px
        padding: 0 42px
        border: 1px solid transparent       
        position: relative
        &:before,&:after
            display: none
            content: ''
            position: absolute
            top: -1px
            
            height: 37px
            width: 8px
            
        &:before
            +bg(navleft)
            left: -1px
        &:after
            right: -1px
            +bg(navright)
                  
// ------------------------------
// ︿(￣︶￣)︿ 平板和手机
// ------------------------------
@media screen and (max-width: 1200px)


// ------------------------------
// ︿(￣︶￣)︿ 平板
// ------------------------------

@media screen and (max-width: 1200px) and (min-width: 768px)   
    
    .headnav
        li
            a 
                padding: 0 20px
    .header 
        .container
            width: 100%
// ------------------------------
// ︿(￣︶￣)︿ 手机
// ------------------------------

@media screen and (max-width: 768px)
    .header
        height: 50px
        .logo
            height: 50px
            border: none!important
            padding: 0 10px
    .headtop    
        height: 0
        .fr 
            display: none    
        .logo 
            position: absolute
            top: 0
            left: 0
            background-color: #e4e4e4
    .iconnav
        display: block
    .headnav
        display: none
        position: absolute
        top: 50px
        left: 0
        width: 100%
        z-index: 100
        background-color: #FFF
        padding-top: 10px
        padding-bottom: 10px
        border-bottom: 1px solid $line
        li 
            display: block
            text-align: left
            padding-left: 20px
            &.on,&:hover
                a 
                    background: none
                    color: $second
                    &:before,&:after
                        display: none
        a
            color: $main
            line-height: 35px
            border: none
            padding: 0
                


