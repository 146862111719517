// ------------------------------
// (*￣︿￣) 
// ------------------------------

@import _/export
@import _/reset
@import _/common
@import m/header
@import m/footer
@import m/slick
// ------------------------------
// ︿(￣︶￣)︿ 
// ------------------------------
.num-list
    text-align: center
    font-size: 0
    padding: 15px 0
    span,a 
        display: inline-block
        border: 1px solid $main 
        color: $main
        width: 30px
        height: 30px
        margin: 0 5px
        font-size: 12px
        line-height: 28px
        &.current
            color: $second
            background-color: $main
