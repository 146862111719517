//==============================================
// @reset
//==============================================

body, dl, dd, ul, ol, h1, h2, h3, h4, h5, h6, pre, form, fieldset, legend, input, textarea, optgroup, p, blockquote, figure, hr, menu, dir, thead, tbody, tfoot, th, td
    margin: 0
    padding: 0

ul, ol
    list-style-type: none
    list-style-image: none

a
    text-decoration: none
    outline: 0 none
    &:active
        // background-color: transparent
    &:active, &:hover
        outline: 0 none
    &:focus
        // outline: 1px dotted

h1, h2, h3, h4, h5, h6, em, 
    font-weight: normal

i, em
    // font-style: normal
em
    font-style: normal
abbr, acronym
    border-bottom: 1px dotted
    cursor: help

dfn
    font-style: italic

hr
    box-sizing: content-box
    height: 0

mark
    background-color: #D2E5FF
    color: #000

code, kbd, pre, samp
    // font-family: monaco,menlo,consolas,'courier new',courier,monospace
    font-family: consolas,'MicroSoft YaHei'

pre
    white-space: pre
    white-space: pre-wrap
    word-wrap: break-word
    overflow: auto

q
    quotes: none
    &:before, &:after
    content: ''
    content: none

small
    font-size: 85.7%

sub, sup
    font-size: 75%
    line-height: 0
    position: relative
    vertical-align: baseline

sup
    top: -0.5em

sub
    bottom: -0.25em

img
    border: 0 none
    // width: auto\9
    // height: auto
    max-width: 100%
    max-height: 100%
    vertical-align: top
    -ms-interpolation-mode: bicubic

table
    border-collapse: collapse
    border-spacing: 0

button, input, select, textarea
    font-family: inherit
    font-size: 100%
    margin: 0
    vertical-align: baseline
    outline: none
textarea
    resize: none
input, button
    *overflow: visible

button, select
    text-transform: none

button, html input[type="button"], input[type="reset"], input[type="submit"]
    -webkit-appearance: button
    cursor: pointer

button[disabled], input[disabled]
    cursor: default

input[type="checkbox"], input[type="radio"]
    padding: 0

input[type="search"]
    -webkit-appearance: textfield
    box-sizing: content-box

input[type="search"]::-webkit-search-decoration
    -webkit-appearance: none

button::-moz-focus-inner, input::-moz-focus-inner
    border: 0
    padding: 0

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button
    height: auto

textarea
    overflow: auto
    // resize: vertical

@media screen and (-webkit-min-device-pixel-ratio: 0)
    input
        line-height: normal !important

input::-moz-placeholder, textarea::-moz-placeholder
    color: darkGray
    opacity: 1

label
    cursor: pointer


svg:not(:root)
    overflow: hidden

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary
    display: block

audio, canvas, video, progress
    display: inline-block
    vertical-align: baseline

audio:not([controls])
    display: none
    height: 0

[hidden], template
    display: none
